import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Alain Ritchie | Problem Solver', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my online resume.',
};

// HERO DATA
export const heroData = {
  title: 'Hello. My name is',
  name: 'Alain Ritchie.',
  subtitle: "I'm an experienced technical leader.",
  cta: 'Tell me more',
};

// ABOUT DATA
export const aboutData = {
  img: 'headshot.jpg',
  paragraphOne:
    'I am a seasoned technical leader with over 17 years of experience in software development, cloud architecture, data and digital transformation.',
  paragraphTwo:
    'Tenacious and dedicated in character, I have a history of delivering innovative and successful solutions across corporate, SME and lean start-up environments.',
//  paragraphThree:
//    'Always looking for a challenge, I am now seeking new opportunities to help a company transform their ability to leverage data and technology to achieve new levels of success.',
  resume: 'https://www.linkedin.com/in/alainritchie/',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// EXPERIENCES DATA
export const experiencesData = [
   {
    id: nanoid(),
    img: 'image-49.jpg',
    company: 'Neverbland',
    title: 'Technical Director',
    duration: 'Feb 2021 - Present',
    role:
      'A member of the Senior Leadership Team and reporting to the CEO, leading a team of hybrid working software engineers and solutions architects to design and deliver high profile client projects, including digital products (SaaS), eCommerce platforms and marketing websites.',
    roleExpansion:
      'Delivery of technological directorship, solutions architecture and technical discovery consultancy, in order to find solutions to business, data and technology related problems.',
    about:
      'Neverbland design and build amazing websites and digital products for our incredibly talented and enlightened clients, and we do so with a real focus on the greater good.',
    url: 'https://neverbland.com',
  },
  {
    id: nanoid(),
    img: 'data-vis.jpg',
    company: 'Matrix APA',
    title: 'Head of Business Intelligence & Technology',
    duration: 'Feb 2018 - Feb 2022',
    role:
      'Leading a talented team of internal and external people to create an outstanding technology ecosystem and first class Business Intelligence to support and accelerate a well-informed and purpose-led business as part of the Executive Leadership Team, reporting to the CEO.',
    roleExpansion:
      'Building and implementing a digital transformation strategy that drives business process efficiency, productivity and profitability through the identification of opportunity, solutions and effective methods of delivery.',
    about:
      'Matrix create and deliver amazing brands and products in beauty and lifestyle and are a certified B-Corp.',
    url: 'https://www.thisismatrix.com',
  },
  {
    id: nanoid(),
    img: 'code.jpg',
    company: 'Intuita Consulting',
    title: 'Senior Consultant',
    duration: 'Jan 2014 - Feb 2018',
    role:
      'Primarily focused on the development of cross-platform solutions to make data sets of varying sizes (Big Data through to small holdings) available for business improvement and understanding.',
    about:
      'A boutique, full service data consultancy, providing quality driven data leadership, implementation and support.',
    url: 'http://www.intuitaconsulting.com',
  },
  {
    id: nanoid(),
    img: 'motivo-1.png',
    company: 'Motivo',
    title: 'Co-Founder',
    duration: 'Sept 2012 - Oct 2019',
    role: 'Directorship of a self-funded business start-up.',
    about:
      'Motivo developed and launched a uniquely motivational solution to the confusing and mundane task of indoor cycle training using an automated digital coaching app.',
  },
  {
    id: nanoid(),
    img: 'phone.jpg',
    company: 'Vodafone UK',
    title: 'CRM Capability Manager',
    duration: 'Oct 2007 - Jan 2014',
    role:
      'Leading a team of technical specialists to support, develop and deliver customer-facing business solutions. Residing within a CRM business function, this role acts as the technical-business interface, ensuring current and future technical CRM requirements are delivered.',
    about:
      'Vodafone is a leader in technology communications through mobile, fixed, broadband and TV.',
    url: 'https://www.vodafone.com',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to see if we can work together? I do.',
  btn: 'Get in Touch',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/alainritchie/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/MatrixAlain',
    },
  ],
};
